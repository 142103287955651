<template>
    <div class="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</template>
<script>
export default {
    data() {
        return {
            code: ''
        };
    },
    mounted(){
        this.$store.commit("hideTabbar");
        this.$store.commit("getWxSDK");
        if (this.getQuery('code') && !localStorage.getItem('token')) {
            this.Request('wechat_mp_login','post',{ code: this.getQuery('code') },false).then(() => {
                location.replace(process.env.VUE_APP_BASE_URL + localStorage.getItem('is_token'));
            })
        }else{
            location.replace(process.env.VUE_APP_BASE_URL + localStorage.getItem('is_token'));
        }
    },
    methods:{
        getQuery(variable) {
            var query = unescape(window.location.href);
            var lengths = query.split("?");
            var vars = lengths[lengths.length - 1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return false;
        },
    }
}
</script>
<style scoped>
.loading {
    width: 160px;
    height: 80px;
    margin: 0 auto;
    padding-top: 200px;
    display: flex;
    justify-content: space-between;
}
.loading span {
    display: inline-block;
    width: 16px;
    height: 100%;
    border-radius: 8px;
    background: lightgreen;
    -webkit-animation: load 1s ease infinite;
}
@-webkit-keyframes load {
    0%,
    100% {
        height: 80px;
        background: lightgreen;
    }
    50% {
        height: 140px;
        margin: -30px 0;
        background: lightblue;
    }
}
.loading span:nth-child(2) {
    -webkit-animation-delay: 0.2s;
}
.loading span:nth-child(3) {
    -webkit-animation-delay: 0.4s;
}
.loading span:nth-child(4) {
    -webkit-animation-delay: 0.6s;
}
.loading span:nth-child(5) {
    -webkit-animation-delay: 0.8s;
}
</style>